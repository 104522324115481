import localesEn from "./en/profile_page.json";
import localesEs from "./es/profile_page.json";
import localesFr from "./fr/profile_page.json";
import localesKo from "./ko/profile_page.json";
import localesRu from "./ru/profile_page.json";
import localesZh from "./zh/profile_page.json";
import localesPt from "./pt/profile_page.json";
import localesDe from "./de/profile_page.json";
import localesJa from "./ja/profile_page.json";

export default function getProfileLocales(locale) {
	switch (locale) {
		case "pt":
			return localesPt;
		case "es":
			return localesEs;
		case "ru":
			return localesRu;
		case "fr":
			return localesFr;
		case "ko":
			return localesKo;
		case "zh":
			return localesZh;
		case "de":
			return localesDe;
		case "ja":
			return localesJa;
		default:
			return localesEn;
	}
}
