import { useState } from "react";
import { useRouter } from "next/router";
import style from "./FaqComponent.module.scss";
import getFaqLocales from "../../locales/locales_faq";
import { HandySvg } from "handy-svg";
import getCommonLocales from "../../locales/locales_common";
import Link from "next/link";

function FaqComponent({ dictionary, pageName, isPurpleBack = false }) {
	const { locale } = useRouter();
	const dictionaryFaq = getFaqLocales(locale);

	const pageObj = dictionary[pageName] ? dictionary[pageName] : { ...getCommonLocales("en") }[pageName];
	const faq = pageObj ? pageObj["faq"] : null;
	let faqSize = faq && Object.keys(faq).length / 2;

	const questionsData = (() => {
		let arr = [];

		if (pageName === "main") {
			for (let i = 1; i < 5; i++) arr = [...arr, { id: i, question: dictionary[`main_page_faq_question${i}`], answer: dictionary[`main_page_faq_answer${i}`] }];
			return arr;
		}

		if (pageName === "page_editor") {
			for (let i = 1; i < faqSize; i++) arr = [...arr, { id: i, question: faq[`question_${i + 1}`], answer: faq[`answer_${i + 1}`] }];
			return arr;
		}

		for (let i = 0; i < faqSize; i++) arr = [...arr, { id: i, question: faq[`question_${i + 1}`], answer: faq[`answer_${i + 1}`] }];
		return arr;
	})();

	const [openQuestions, setOpenQuestion] = useState([]);
	const checkActiveQuestion = (id) => {
		return openQuestions.includes(id);
	};
	const toggleQuestion = (id) => {
		checkActiveQuestion(id)
			? setOpenQuestion((prev) => {
					return prev.filter((item) => {
						return item !== id;
					});
			  })
			: setOpenQuestion((prev) => {
					return [...prev, id];
			  });
	};

	return (
		<section className={`${style.block} ${isPurpleBack ? style.purple : ""}`}>
			<div>
				<h2 className={style.title}>{dictionaryFaq["page title"]}</h2>
				{pageName === "main" && (
					<Link href={"/faq/"} className={style.btn}>
						{dictionary["FAQ"]} <HandySvg src={"/illustration_service/next.svg"} width={24} height={24} fill={"transparent"} />
					</Link>
				)}
			</div>

			<div className={style.container}>
				{questionsData.map((item) => (
					<div onClick={() => toggleQuestion(item.id)} key={item.id} className={style.item}>
						<div className={`${style.question} ${checkActiveQuestion(item.id) ? style.active : ""}`}>
							<h3>{dictionary["fag"][item.question] || dictionary["faq"][item.question] || dictionary[item.question] || item.question}</h3>
							<HandySvg className={style.arrow} src={"/remove_back/magic_bg/arrow.svg"} width={18} height={10} />
						</div>
						<div className={style.answer}>
							<div>{item.answer}</div>
						</div>
					</div>
				))}
			</div>
		</section>
	);
}

export default FaqComponent;
