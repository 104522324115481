import Image from "next/image";
import styles from "./NewUserReviews.module.scss";
import { memo, useRef, useState } from "react";
import { HandySvg } from "handy-svg";

function NewUserReviews({ dictionary }) {
	const reviews = [
		{ location: "US", title: "page_main_reviewhead1", body: dictionary["page_main_review1"], name: "Marc", profession: "Social Media Manager", imgUrl: "/main_page/reviews/photo/marc.png" },
		{ location: "ID", title: "page_main_reviewhead2", body: dictionary["page_name_review2"], name: "Emily", profession: "Illustrator", imgUrl: "/main_page/reviews/photo/emily.png" },
		{ location: "BR", title: "page_main_reviewhead3", body: dictionary["page_main_review3"], name: "Vikram", profession: "Freelancer", imgUrl: "/main_page/reviews/photo/vikram.png" },
		{ location: "IND", title: "page_main_reviewhead4", body: dictionary["page_main_review4"], name: "Olivia", profession: "Blogger", imgUrl: "/main_page/reviews/photo/olivia.png" },
		{ location: "ESP", title: "page_main_reviewhead5", body: dictionary["page_main_review5"], name: "Anna", profession: "Digital Artist", imgUrl: "/main_page/reviews/photo/anna.png" },
		{ location: "ESP", title: "page_main_reviewhead6", body: dictionary["page_main_review6"], name: "Ryan", profession: "Entrepreneur", imgUrl: "/main_page/reviews/photo/ryan.png" },
	];

	const title = dictionary["page_main_header_reviews"];
	const starArr = new Array(5).fill(1);
	const [open, setOpen] = useState(false);
	const showMoreBtn = useRef(null);

	const handleOpen = () => {
		setOpen(!open);
		if (open) setTimeout(() => showMoreBtn.current.scrollIntoView({ block: "center", behavior: "smooth" }), 1);
	};

	return (
		<section className={styles.container}>
			<h2 className={styles.title}>{title}</h2>
			<div className={`${styles.itemsWrapper} ${open ? styles.open : styles.close}`}>
				{reviews.map((review, index) => (
					<div key={review.title} className={`${styles.item} ${index > 1 && !open ? styles.close : styles.open}`}>
						<h3 className={styles.itemTitle}>{dictionary[review.title]}</h3>
						<p className={styles.itemBody}>{review.body}</p>
						<div className={styles.user}>
							<Image width={44} height={44} src={review.imgUrl} alt={review.name} />
							<div>
								<h4>{review.name}</h4>
								<h5>{review.profession}</h5>
							</div>
							<div className={styles.stars}>
								{starArr.map((_, idx) => (
									<HandySvg key={idx} src="/main_page/reviews/filled_star.svg" width="20" height="20" fill={"#1c1f1e"} />
								))}
							</div>
						</div>
					</div>
				))}
			</div>
			<button ref={showMoreBtn} className={styles.showMore} onClick={handleOpen}>
				<HandySvg src="/illustration_service/back.svg" width={24} height={24} className={styles.item_next} /> {dictionary["show_more"] || "Show more"}
			</button>
		</section>
	);
}

export const MemoizedReviews = memo(NewUserReviews);
