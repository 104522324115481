import Head from "next/head";

const OpenGraphTwitterCard = ({ title, description, image, url, noindex, canonical }) => {
	return (
		<Head>
			{/* Page title */}
			<title>{title}</title>

			{/* Meta description */}
			<meta name="description" content={description} />

			{/* Open Graph tags */}
			<meta property="og:title" content={title} key="og-title" />
			<meta property="og:description" content={description} key="og-description" />
			<meta property="og:image" content={image ? image : "https://bgrem.ai/seo/og.jpg"} key="og-image" />
			<meta property="og:url" content={`https://bgrem.ai${url !== "/" ? url + "/" : "/"}`} key="og-url" />
			<meta property="og:type" content="website" key="og-type" />

			{/* Twitter Card tags */}
			<meta name="twitter:title" content={title} key="twitter-title" />
			<meta name="twitter:description" content={description} key="twitter-description" />
			<meta name="twitter:image" content={image ? image : "https://bgrem.ai/seo/og.jpg"} key="twitter-image" />
			<meta name="twitter:card" content="summary_large_image" key="twitter-card" />
			<meta name="twitter:site" content="@BgRemAI" key="twitter-site" />

			{/* Noindex tag */}
			{noindex && <meta name="robots" content="noindex" />}
			{canonical && <link rel="canonical" href={canonical} />}
		</Head>
	);
};

export default OpenGraphTwitterCard;
