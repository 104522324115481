import Link from "next/link";
import style from "./MadeWith.module.scss";
import Image from "next/image";
import { HandySvg } from "handy-svg";
import { useRef, useState } from "react";

const itemsArr = [
	{
		url: "/illustration-drawing/",
		img: "1",
		serviceName: "aI_illustrator_drawing",
		size: { width: 760, height: 456 },
		color: "#b48460",
	},
	{
		url: "/text-to-image-generator/",
		img: "2",
		serviceName: "Text-to-image AI generator",
		size: { width: 370, height: 456 },
		color: "#d8ddd7",
	},
	{
		url: "/text-to-image-generator/",
		img: "3",
		serviceName: "Text-to-image AI generator",
		size: { width: 370, height: 456 },
		color: "#aeb8ac",
	},
	{
		url: "/illustration-drawing/",
		img: "4",
		serviceName: "aI_illustrator_drawing",
		size: { width: 370, height: 456 },
		color: "#b48b56",
	},
	{
		url: "/interior-design-planner/",
		img: "5",
		serviceName: "interior_redesign",
		size: { width: 370, height: 456 },
		color: "#dbdcdc",
	},
	{
		url: "/photo-into-painting/",
		img: "6",
		serviceName: "photo_into_painting",
		size: { width: 370, height: 456 },
		color: "#93552e",
	},
	{
		url: "/photo-into-painting/",
		img: "7",
		serviceName: "photo_into_painting",
		size: { width: 370, height: 456 },
		color: "#b4b3b4",
	},
	{
		url: "/text-to-image-generator/",
		img: "8",
		serviceName: "Text-to-image AI generator",
		size: { width: 370, height: 456 },
		color: "#dc3c74",
	},
	{
		url: "/photo-into-painting/",
		img: "9",
		serviceName: "photo_into_painting",
		size: { width: 370, height: 456 },
		color: "#c0a751",
	},
	{
		url: "/illustration-drawing/",
		img: "10",
		serviceName: "aI_illustrator_drawing",
		size: { width: 760, height: 456 },
		color: "#a47c5c",
	},
	{
		url: "/text-to-image-generator/",
		img: "11",
		serviceName: "Text-to-image AI generator",
		size: { width: 370, height: 932 },
		color: "#3394ab",
	},
	{
		url: "/photo-into-painting/",
		img: "12",
		serviceName: "photo_into_painting",
		size: { width: 370, height: 456 },
		color: "#eab6bf",
	},
	{
		url: "/interior-design-planner/",
		img: "13",
		serviceName: "interior_redesign",
		size: { width: 370, height: 456 },
		color: "#8c6a45",
	},
	{
		url: "/photo-into-painting/",
		img: "14",
		serviceName: "photo_into_painting",
		size: { width: 370, height: 456 },
		color: "#dd7c75",
	},
	{
		url: "/photo-into-painting/",
		img: "15",
		serviceName: "photo_into_painting",
		size: { width: 760, height: 456 },
		color: "#d8cec7",
	},
	{
		url: "/photo-into-painting/",
		img: "16",
		serviceName: "photo_into_painting",
		size: { width: 370, height: 456 },
		color: "#727553",
	},
];

function MadeWith({ dictionary, locale }) {
	const [open, setOpen] = useState(false);
	const showMoreBtn = useRef(null);

	const title = dictionary["made_with_bgrem_ai"] || "Made with BGREM AI";
	const description =
		dictionary["made_with_bgrem_ai_description"] ||
		"The creative possibilities with Bgrem are limitless. Our users craft various forms of art, generating images in 17 styles. They portray themselves using AI filters as heroes in popular games and movies, redesign their room interiors and even create unique illustrations for children’s books. Truly, creativity knows no bounds.";

	const handleOpen = () => {
		setOpen(!open);
		if (open) setTimeout(() => showMoreBtn.current.scrollIntoView({ block: "center", behavior: "smooth" }), 1);
	};

	return (
		<>
			<section className={style.container}>
				<h2 className={style.title}>{title}</h2>
				<p className={style.description}>{description}</p>
				<div className={`${style.itemsWrapper} ${open ? style.open : style.close}`}>
					{itemsArr.map((item, index) => {
						return (
							<Link
								key={item.serviceName + index}
								href={item.url}
								className={`${style.item} ${style[`item${item.img}`]} ${index > 4 && index !== 7 && !open ? style.close : style.open}`}
								style={{ backgroundColor: item.color }}
							>
								<Image src={`/main_page/made_with/${item.img}.jpg`} alt={item.serviceName} width={item.size.width} height={item.size.height} quality={70} loading="lazy" />
								<div className={style.text}>
									<button className={style.btn}>
										{dictionary["try_ai_tool"] || "Try AI tool"} <HandySvg src="/illustration_service/back.svg" width={22} height={22} className={style.item_next} />
									</button>
									<h3>{dictionary[item.serviceName]}</h3>
								</div>
							</Link>
						);
					})}
				</div>
				<button ref={showMoreBtn} className={style.showMore} onClick={handleOpen}>
					<HandySvg src="/illustration_service/back.svg" width={24} height={24} className={style.item_next} /> {dictionary["show_more"] || "Show more"}
				</button>
			</section>
		</>
	);
}

export default MadeWith;
