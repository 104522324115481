import { HandySvg } from "handy-svg";
import style from "./About.module.scss";
import Image from "next/image";
import Link from "next/link";

function About({ dictionary, locale }) {
	const title = dictionary["about_bgrem_ai_header"] || "About BGREM AI";
	const description =
		dictionary["about_bgrem_description"] ||
		`We are a group of machine learning developers specialising in the creation of user-friendly services for the generation of content, including images and videos, using artificial
	intelligence. Our rapid growth and development is due to the global recognition of our users, who consistently praise our work worldwide.`;

	return (
		<>
			<section className={style.container}>
				<div className={`${style.div1} ${style.text}`}>
					<h2>{title}</h2>
					<p>{description}</p>
				</div>
				<div className={`${style.div2} ${style.affiliate}`}>
					<h2>{dictionary["earn_money_with_bgrem"] || "Earn money with BGREM affiliate program!"}</h2>
					<p>{dictionary["take_part_in_affiliate_program"] || "Take part in our affiliate program and earn 30% commissions from every purchase"}</p>
					<div className={style.wrapper}>
						<Image className={style.item_img} src={`/main_page/inform/img.jpg`} alt={"affiliate program image"} width={178} height={159} quality={90} />
						<Link href={"/affiliates/"} className={`${style.btn} ${style[locale]}`}>
							{dictionary["join"] || "Join"} <HandySvg src="/illustration_service/back.svg" width={24} height={24} className={style.item_next} />
						</Link>
					</div>
				</div>
				<div className={`${style.div3} ${style.info}`}>
					<h2>1,3M+</h2>
					<h3>{dictionary["users"] || "Users"}</h3>
					<p>{(dictionary["from_n_countries"] || "From N countries").replace("N", "173")}</p>
				</div>
				<div className={`${style.div4} ${style.info}`}>
					<HandySvg src="/main_page/inform/infinity.svg" width={58} height={58} />
					<h3>{dictionary["time_saved_header"] || "Time saved"}</h3>
					<p>{dictionary["time_saved_description"] || "Bgrem's goal is to save users time, simplify tasks and take creativity to the next level"}</p>
				</div>
				<div className={`${style.div5} ${style.info} ${style.infoItems}`}>
					<div>
						<h2>1M+</h2>
						<h3>{dictionary["generated_images"] || "Generated images"}</h3>
					</div>
					<div>
						<h2>15K+</h2>
						<h3>{dictionary["hours_proceed_video"] || "Hours proceed video"}</h3>
					</div>
				</div>
				<div className={`${style.div6} ${style.info}`}>
					<h2>23%</h2>
					<h3>{dictionary["price_lower_than_the_competitors_header"] || "Price lower than the competitors"}</h3>
					<p>{dictionary["price_lower_than_the_competitors_description"] || "*Assessment of Monthly Basic Plans in comparison with three competitors offering similar services"}</p>
				</div>
			</section>
		</>
	);
}

export default About;
