import { HandySvg } from "handy-svg";
import style from "./UseCases.module.scss";
import Image from "next/image";
import { useState } from "react";
import Link from "next/link";

const casesLIst = [
	{
		title: "page_anime_generator_header",
		description: "page_main_block_ai_anime_generator_desription",
		link: "/anime-generator/",
		imgUrl: "1",
		color: "#b8ccd9",
	},
	{
		title: "page_sketch_h1",
		description: "page_main_block_photo_to_sketch_converter_desription",
		link: "/photo-to-sketch/",
		imgUrl: "2",
		color: "#cfcfcf",
	},
	{
		title: "barbie_selfie_generator",
		description: "page_main_block_barbie_selfie_generator_desription",
		link: "/barbie-selfie-generator/",
		imgUrl: "3",
		color: "#f2dbd6",
	},
	{
		title: "barbie_interior",
		description: "page_main_block_barbie_interior_desription",
		link: "/barbie-interior/",
		imgUrl: "4",
		color: "#e765a2",
	},
	{
		title: "page_editor_h1",
		description: "page_main_photo_editor_desription_2",
		link: "/editor/",
		imgUrl: "5",
		color: "#c1d4db",
	},
	{
		title: "GTA",
		description: "page_main_block_gta_desription",
		link: "/landings/gta-ai-filter/",
		imgUrl: "6",
		color: "#ecb590",
	},
	{
		title: "superhero",
		description: "page_main_block_superhero_ai_generator_desription",
		link: "/landings/superhero-generator/",
		imgUrl: "7",
		color: "#55c4e5",
	},
];

function UseCases({ dictionary }) {
	const [activeItem, setActiveItem] = useState(casesLIst[0]);

	const title = dictionary["use_cases_footer"] || "Use cases";

	return (
		<section className={style.container}>
			<h2 className={style.title}>{title}</h2>
			<div className={`${style.useCasesWrapper}`}>
				{casesLIst.map((item) => {
					const title = dictionary[item.title] || item.title;
					const isActiveItem = activeItem.title === item.title;
					const handleActiveItem = () => !isActiveItem && setActiveItem(item);

					return (
						<div
							onMouseEnter={handleActiveItem}
							onClick={handleActiveItem}
							key={item.title}
							className={`${style.useCase} ${isActiveItem ? style.active : ""}`}
							style={{ backgroundColor: item.color }}
							data-title={title}
						>
							<Image src={`/main_page/use_cases/${item.imgUrl}.jpg`} width={630} height={110} loading="lazy" quality={90} alt={title} />

							<div className={style.dataWrapper}>
								<h3 className={style.itemTitle}>{title}</h3>
								<p className={style.itemDescription}>{dictionary[item.description] || item.description}</p>
								<Link href={item.link} className={style.itemBtn}>
									{dictionary["discover"] || "Discover"} <HandySvg src="/illustration_service/back.svg" width={22} height={22} className={style.item_next} />
								</Link>
							</div>
						</div>
					);
				})}
			</div>
		</section>
	);
}

export default UseCases;
