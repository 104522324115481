import AuthModal from "../../AuthModal/AuthModal";
import style from "./BottomBanner.module.scss";
import { useState } from "react";

function BottomBanner({ dictionary }) {
	const [showAuthModal, setShowAuthModal] = useState(false);

	const title = dictionary["everything_you_need_to make_anything_you_want"] || "Everything you need to make anything you want";

	return (
		<>
			<section className={style.container}>
				<h3 className={style.title}>{title}</h3>
				<button className={style.btn} onClick={() => setShowAuthModal(true)}>
					{dictionary["Try for free"]}
				</button>
			</section>
			{showAuthModal && <AuthModal closeHandler={() => setShowAuthModal(false)} serviceName="mainPage" />}
		</>
	);
}

export default BottomBanner;
